<template>
    <v-container 
        fluid  
        fill-height
        style="display: flex; justify-content: center; align-items: center; "
    >
        <v-card
            elevation="1"
            :width="$vuetify.breakpoint.width>1264?'60%':$vuetify.breakpoint.width>960?'90%':'100%'"
            outlined
            style="padding: 50px 30px;display: flex; justify-content: center; align-items: center; flex-direction: column;"
        >
            <v-card-title 
                v-if="$vuetify.breakpoint.width>400"
                class="text-h4 font-weight-medium" 
                style="text-align: center;margin-bottom: 30px;"
            >
                Change Password
            </v-card-title>
            <v-card-title 
                v-else
                class="text-h4 font-weight-medium" 
                style="text-align: center;margin-bottom: 30px;"
            >
                Change
                <br> Password
            </v-card-title>
            <div :style="$vuetify.breakpoint.width<600?'width:90%;':''">
                <v-text-field
                        v-model="cPassword"
                        label="Current Password"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show = !show"
                        :type="show ? 'text' : 'password'"
                        :rules="[() => !!cPassword || 'Current Password should be checked']"
                        persistent-placeholder
                        style="width: 400px;"
                        clearable
                        @input="pwdValidation"
                ></v-text-field>
            </div>
            <div :style="$vuetify.breakpoint.width<600?'width:90%;':''">
                <v-text-field
                        v-model="nPassword"
                        label="New Password"
                        style="width: 400px;"
                        :rules="passwordRules"
                        persistent-placeholder
                        clearable
                        :type="show1 ? 'text' : 'password'"
                        @input="pwdValidation"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show1 = !show1"
                ></v-text-field>
            </div>
            <div style=" display: flex; align-items: baseline; justify-content: center; width: fit-content; position: relative;" :style="$vuetify.breakpoint.width<600?'width:90%;':''">
                <v-text-field
                        v-model="chPassword"
                        label="Password Check"
                        style="width: 400px;"
                        :rules="passwordRules"
                        persistent-placeholder
                        clearable
                        :type="show2 ? 'text' : 'password'"
                        @input="pwdValidation"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show2 = !show2"
                ></v-text-field>
                <v-btn 
                    icon 
                    small 
                    color="green darken-3" 
                    v-if="chkPwd" 
                    style="margin-left: 10px;position: absolute; right: -40px; bottom: 25px;"
                    :style="$vuetify.breakpoint.width<400?'right:-30px;':''"
                    >
                        <img id="checkBtn" src="@/assets/check.svg">
                </v-btn>
            </div>
            <div style="display: flex; justify-content: center; width: 100%; gap: 5px; margin-top: 20px;">
                    <v-btn small elevation="" outlined color="error" :to="'/'">
                        cancel
                    </v-btn>
                    <v-btn small elevation="" outlined color="green darken-3" @click="savePwd">
                        save
                    </v-btn>
                </div>
        </v-card>
    </v-container>
</template>

<script>
// import { address } from '@/mixins/api';
import store from '@/store/store';
import axios from 'axios';
import Swal from 'sweetalert2';
// import Swal from 'sweetalert2';

export default {
    name:'SettingPage',
    data:()=>({
        cPassword: null,
        chPassword: null,
        nPassword: null,
        show: false,
        show1: false,
        show2: false,
        chkPwd:false,
        passwordRules: [
            (v) => !!v || "Password is required",
            (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
        ],
    }),
    methods:{
        pwdValidation:function(){
            if(this.chPassword==null){
                this.chkPwd=false;
                return 'Check Password is required';
            }else{
                if(this.nPassword === this.chPassword && this.nPassword.length >= 8 && this.chPassword.length >= 8){
                    this.chkPwd=true;
                    return true
                }else{
                    this.chkPwd=false;
                    return 'Password do not match'
                }
            }
            // if(this.nPassword !=this.chPassword && this.nPassword.length < 8 || this.chPassword.length < 8){
            //     this.chkPwd=false;
            //     return 'Password do not match'
            // }else{
            //     if(this.chPassword==null){
            //         this.chkPwd=false;
            //         return 'Check Password is required';
            //     }else{
            //         this.chkPwd=true;
            //         return true;
            //     }
            // }
            
        },
        savePwd:function(){
            if(this.chkPwd && this.cPassword!=null && this.chPassword === this.nPassword){
                axios.post(`${this.$apiUrl}/user/pwchange`,{
                    ori_pw: this.cPassword,
                    new_pw: this.chPassword
                },{
                    withCredentials: true
                }).then((res)=>{
                    console.log(res);
                    if(res.data.result){
                        // alert("password is changed!");
                        Swal.fire({
                            title: 'Password is changed!',
                            text: "Please log in again with your new password.",
                            icon: 'success',
                            width: '400px',
                            confirmButtonText:'OK',
                            confirmButtonColor: '#4CAF50',
                            customClass:{
                                confirmButton: 'custom-confirm-button'
                            }
                        }).then((result)=>{
                            if(result.isConfirmed){
                                store.commit("logout");
                                this.$router.push('/login');
                            }
                        });
                    }else{
                        Swal.fire({
                            text: 'Please check your current password!',
                            icon: 'error',
                            width: '400px',
                            confirmButtonText:'OK',
                            confirmButtonColor: '#FF9800',
                            customClass:{
                                confirmButton: 'custom-confirm-button'
                            }
                        }).then((result)=>{
                            if(result.isConfirmed){
                                this.$router.go(this.$router.currentRoute);
                            }
                        });
                        // alert("Please check your current password!")
                    }
                })
            }else{
                // alert("check again");
                Swal.fire({
                    text: 'Please check the new password matches',
                    icon: 'error',
                    width: '400px',
                    confirmButtonText:'OK',
                    confirmButtonColor: '#FF9800',
                    customClass:{
                        confirmButton: 'custom-confirm-button'
                    }
                }).then((result)=>{
                    if(result.isConfirmed){
                        this.$router.go(this.$router.currentRoute);
                    }
                });
    }
        }
    }
}
</script>

<style scoped>
#checkBtn{
    filter: invert(39%) sepia(11%) saturate(2527%) hue-rotate(73deg) brightness(99%) contrast(91%);
}
.custom-confirm-button{
    color: white;
}
</style>