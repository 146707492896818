import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store/store'
import axios from 'axios'
import router from './router'


axios.interceptors.response.use(
  (response) => response,
  (error)=>{
    console.log("interceptors")
    if(error.response && error.response.status === 401){
      store.commit("logout");
      if(router.currentRoute.path !=="/login"){
        router.push("/login");
      }
    }
    return Promise.reject(error);
  }
)

Vue.config.productionTip = false

console.log('Current Environment: ', process.env.NODE_ENV);

const apiUrl = process.env.NODE_ENV === 'production'
  ? 'https://vul-api.wikisecurity.net:8080'
  : 'https://192.168.0.72:8081';

Vue.prototype.$apiUrl= apiUrl;

Vue.prototype.$axios= axios;

axios.defaults.withCredentials = true;
new Vue({
  vuetify,
  store,
  axios,
  router,
  render: h => h(App)
}).$mount('#app')
