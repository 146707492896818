import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const savedState = JSON.parse(localStorage.getItem("vuexState")) || {};

export default new Vuex.Store({
  state: {
    isLogged: savedState.isLogged || false,
    userData: savedState.userData || {
      username: undefined,
      id: undefined,
      password: undefined,
      lastLogin: undefined,
      // check:undefined
    },
    curStatus:undefined,
    curResult:undefined,
  },
  mutations: {
    login(state) {
      state.isLogged = true;
      localStorage.setItem("vuexState", JSON.stringify(state));
    },
    setUser(state, payload) {
      state.userData = payload;
      localStorage.setItem("vuexState", JSON.stringify(state));
    },
    setLogged(state, status) {
      state.isLogged = status;
      localStorage.setItem("vuexState", JSON.stringify(state));
    },
    logout(state) {
      state.isLogged = false;
      state.userData = {
        username: undefined,
        id: undefined,
        password: undefined,
        lastLogin:undefined,
        // check: undefined
      };
      localStorage.removeItem("vuexState");
    },
    //currentStatus 표시하기 위해 status로 처리
    setScanStatus(state, payload){
      state.curStatus=payload;
      console.log("scan status");
      console.log(state.curStatus);
    },
    //값 제거
    resetScanStatus(state) {
      state.curStatus = null;
    },
    setCurResult(state, payload){
      state.curResult=payload;
      console.log(state.curResult);
    }
  },
  actions: {
    logout({ commit }) {
      return new Promise((resolve) => {
        setTimeout(() => {
          commit("logout");
          resolve();
        }, 500);
      });
    },
  },
});
