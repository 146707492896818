<template>
  <v-container 
    fluid
    style="display: flex; justify-content: center; align-items: center;"
  >
    <v-card outlined class="pa-2" >
      <div @click="gotop" style="width: 50px; height: 50px; position: fixed; bottom: 10px; right: 10px; z-index: 1000;">
        <img src="@/assets/arrow-up-bold-circle.svg" class="gotop">
      </div>

      <div :class="$vuetify.breakpoint.width<=600?'d-flex flex-column':'d-flex'" style="gap: 10px; margin-bottom: 5px; margin-top: 20px; padding-left: 16px;">
        <v-chip
        style="border: 2px solid #B0BEC5;"
        :outlined="this.selected!='overview'"
        :class="{'highlight': this.selected=='overview'}"
        @click="changeLocation('overview')"
        >1. Overview</v-chip>
        <v-chip
        style="border: 2px solid #B0BEC5;"
        :outlined="selected!='rule'"
        :class="{'highlight': selected=='rule'}"
        @click="changeLocation('rule')"
        >2. Scan Ruleset</v-chip>
        <v-chip
        style="border: 2px solid #B0BEC5;"
        :outlined="selected!='how'"
        :class="{'highlight': selected=='how'}"
        @click="changeLocation('how')"
        >3. User Guide</v-chip>
        <v-chip
        style="border: 2px solid #B0BEC5;"
        :outlined="selected!='dev'"
        :class="{'highlight': selected=='dev'}"
        @click="changeLocation('dev')"
        >4. Libraries and Tools Utilized</v-chip>
      </div>

      <div id="overview" style="margin-bottom: 30px;">
        <v-card-title class="text-h5 font-weight-medium" style="padding-bottom: 0px;">1. Overview</v-card-title>
          <!-- <div class="pa-5" v-if="!overview1" @mouseover="overview1=true" style=" height: 7.5em;"> -->
          <div class="pa-5" v-if="!overview1"  >
            This our powerful Web Application Scanner swiftly scans over 50 well-known vulnerabilities and accurately categorizes them into four severity levels: High, Medium, Low, and Information. 
            Each detected vulnerability is analyzed according to OWASP Top10, CWE, and WASC standards, providing detailed causes and remediation steps to enhance security. 
            This scanner is the optimal choice for strengthening your web application security.
          </div>
          
          <!-- <div class="pa-5" v-if="overview1" @mouseout="overview1=false" style=" height: 7.5em;">
            이 강력한 웹 애플리케이션 스캐너는 50개 이상의 잘 알려진 취약점을 빠르게 스캔하여 4가지 심각도(High, Medium, Low, Information)로 분류합니다. 
            탐지된 각 취약점은 OWASP Top10, CWE, WASC 표준에 따라 분석되어 보안을 강화하기 위한 자세한 원인과 수정 단계를 제공합니다.
            이 스캐너는 당신의 웹 애플리케이션 보안을 강화하기 위한 최적의 선택입니다.
          </div> -->

          <div class="pa-6 d-flex justify-left">
            <span class="comm_bullet">l</span>
            <!-- <div style="margin-left: 10px;height: 4.5em;" v-if="!overview2" @mouseover="overview2=true"> -->
            <div style="margin-left: 10px;" v-if="!overview2" >
              OWASP Top 10: A widely recognized list that identifies the ten most critical security risks to web applications. 
              It is updated regularly by the Open Web Application Security Project (OWASP) and serves as a guideline for developers and security professionals to improve web application security. 
              https://owasp.org/www-project-top-ten/)
            </div>
            <!-- <div style="margin-left: 10px;height: 4.5em;" v-if="overview2" @mouseout="overview2=false">
              OWASP Top10은 웹 애플리케이션에 대한 가장 중요한 보안 위험 10가지를 식별한 널리 알려진 목록이며 Open Web Application Security Project(OWASP)에서 정기적으로 업데이트되며 
              개발자와 보안 전문가가 웹 애플리케이션 보안을 개선하기 위한 지침 역할을 합니다. https://owasp.org/www-project-top-ten/)
            </div> -->
          </div>

          <div class="pa-6 d-flex justify-left">
            <span class="comm_bullet">l</span>
            <!-- <div style="margin-left: 10px;height: 4.5em;" v-if="!overview3" @mouseover="overview3=true"> -->
            <div style="margin-left: 10px;" v-if="!overview3" >
              CWE (Common Weakness Enumeration): A comprehensive list of software weaknesses and vulnerabilities. 
              It categorizes and organizes various types of coding errors and vulnerabilities that could lead to security issues, helping developers and organizations prevent and mitigate these weaknesses. 
              (https://cwe.mitre.org/)
            </div>
            <!-- <div style="margin-left: 10px;  height: 4.5em;" v-if="overview3" @mouseout="overview3=false">
              CWE는 소프트웨어 약점 및 취약성의 포괄적인 목록이며, 보안 문제로 이어질 수 있는 다양한 유형의 코딩 오류 및 취약성을 분류하고 정리하여 
              개발자와 조직이 이러한 약점을 예방하고 완화하는 데 도움이 됩니다. (https://cwe.mitre.org/)
            </div> -->
          </div>

          <div class="pa-6 d-flex justify-left">
            <span class="comm_bullet">l</span>
            <!-- <div style="margin-left: 10px;height: 4.5em;"  v-if="!overview4" @mouseover="overview4=true"> -->
            <div style="margin-left: 10px; width: 100%;"  v-if="!overview4" >
              WASC (Web Application Security Consortium) Threat Classification: A framework that classifies web application security threats. 
              It provides a detailed list of common web security issues and their potential impacts, helping organizations to better understand and address these threats.
              (http://projects.webappsec.org/Threat-Classification)
            </div>
            <!-- <div style="margin-left: 10px; height: 4.5em;"  v-if="overview4" @mouseout="overview4=false">
              WSAC은 웹 애플리케이션 보안 위협을 분류하는 프레임워크이며, 
              일반적인 웹 보안 문제와 잠재적 영향에 대한 자세한 목록을 제공하여 조직이 이러한 위협을 더 잘 이해하고 해결하는 데 도움이 됩니다. 
              (http://projects.webappsec.org/Threat-Classification)
            </div> -->
          </div>

        
        <!-- <v-card-title class="text-h6" style="padding-bottom: 0px;">1.2 Scan Policies</v-card-title> -->
        
      </div>
      
      <div id="rule"  style="margin-bottom: 50px;">
        <v-card-title class="text-h5 font-weight-medium">2. Scan Ruleset</v-card-title>
        <!-- <div class="pa-5" v-if="!rule" @mouseover="rule=true" style="height: 3.0em;"> -->
        <div class="pa-5" v-if="!rule">
          This scanner applies over 50 Web Application Vulnerability Rulesets and continuously developes and applies newly discovered security vulnerabilities.
        </div>
        <!-- <div class="pa-5" v-if="rule" @mouseout="rule=false" style="height: 3.0em;">
          이 스캐너는 50개 이상의 웹 애플리케이션 취약점 Ruleset을 적용하고 있으며 새롭게 발견된 보안 취약점을 지속적으로 개발하여 적용합니다.
        </div> -->
      </div>
      
      <div style="display:flex; width: 100%; flex-wrap: wrap; justify-content: center; margin-bottom: 50px;">
        <v-card class="pa-4 ma-3" :style="$vuetify.breakpoint.width<600?'width:100%; background-color: #ECEFF1;':'width:40%; background-color: #ECEFF1;' " v-for="(pol, l) in policies" :key="l">
          <div class="text-h6">{{ pol.type }}</div>
          <v-divider></v-divider>
          <div v-html="pol.content" class="pa-2 text-caption"></div>
        </v-card>
      </div>

      <div id="how"  style="margin-bottom: 50px; max-width: 700px">
        <v-card-title class="text-h5 font-weight-medium">3. User Guide</v-card-title>

        <div>
          <v-card-subtitle class="text-h6 font-weight-medium" style="padding-bottom: 0px; padding-left: 20px;">3.1 Create a user account</v-card-subtitle>
          <v-timeline
            align-top
            dense
            style="margin-bottom: 30px; "
            v-if="$vuetify.breakpoint.width>600"
          >
            <v-timeline-item
              color="orange"
              small
              style="margin-bottom: 20px;"
            >
              <div style="max-width: 50vw">
                <div class="font-weight-normal">
                  <strong>step 1 </strong> 
                  <div>This scanner can be used after creating a user account according to the established procedure to prevent user misuse.</div>
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
          <div style="margin-left: 20px;" v-else>
            <div class="font-weight-normal">
              <strong>step 1 </strong> 
              <div>This scanner can be used after creating a user account according to the established procedure to prevent user misuse.</div>
            </div>
          </div>
        </div>
        
        <div v-for="(menu, k) in steps" :key="k">
          <v-card-subtitle class="text-h6 font-weight-medium" style="padding-bottom: 0px; padding-left: 20px;">{{ menu.menu }}</v-card-subtitle>
          <v-timeline
            align-top
            dense
            style="margin-bottom: 30px; "
            v-if="$vuetify.breakpoint.width>600" 
          >
            <v-timeline-item
              v-for="(step, j) in menu.steps"
              :key="j"
              color="orange"
              small
              style="margin-bottom: 20px;"
              :style="$vuetify.breakpoint.width<600?'width:100%;':''"
            >
              <div >
                <div class="font-weight-normal">
                  <strong>step {{ j+1 }} </strong> 
                  <div v-html="step.step"></div>
                </div>
                <div v-for="(photo, i) in step.src" :key="i" class="screenshot" >
                  <img :src=photo style="width: 100%; height: 100%;" >
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
          <div v-if="$vuetify.breakpoint.width<=600">
            <div style="margin-left: 20px; margin-bottom: 30px;"  v-for="(step, j) in menu.steps" :key="j"  >
              <div class="font-weight-normal">
                <strong>step {{ j+1 }} </strong> 
                <div style="margin: 5px 0px;" v-html="step.step"></div>
                <div v-for="(photo, i) in step.src" :key="i" >
                    <img :src=photo style="width: 100%; height: 100%;" >
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div id="dev">
        <v-card-title class="text-h5 font-weight-medium" style="padding-bottom: 40px;">4. Libraries and Tools Utilized</v-card-title>
        <div style="width: 100%" class="d-flex justify-center">
          <v-data-table
          :headers="headers"
          :items="items"
          style="width:75%; padding-bottom: 10px"
          :style="$vuetify.breakpoint.width<600?'width:100%;':''"
          hide-default-footer
          />
        </div>
        
      </div>
    </v-card>
  </v-container>
</template>

<script>
import owaspList from "@/assets/owasp.json"

export default {
    name:'AboutMe',
    data:()=>({
      overview1:false,
      overview2:false,
      overview3:false,
      overview4:false,
      rule:false,
      policies:[
        {
          type: 'Information Gathering',
          content: 
            `.env Information Leak<br>
            .htaccess Information Leak<br>
            Directory Browsing<br>
            ELMAH Information Leak<br>
            Heartbleed OpenSSL Vulnerability<br>
            Hidden File Finder<br>
            Remote Code Execution - CVE-2012-1823<br>
            Source Code Disclosure - /WEB-INF Folder<br>
            Source Code Disclosure - CVE-2012-1823<br>
            Spring Actuator Information Leak<br>
            Trace.axd Information Leak<br>
            User Agent Fuzzer`
        },
        {
          type: 'Injection',
          content:
            `Buffer Overflow<br>
            Cloud Metadata Potentially Exposed<br>
            CRLF Injection<br>
            Cross Site Scripting (Persistent)<br>
            Cross Site Scripting (Reflected)<br>
            Format String Error<br>
            Parameter Tampering<br>
            Remote OS Command Injection<br>
            Server Side Code Injection<br>
            Server Side Include<br>
            Server Side Template Injection<br>
            Server Side Template Injection (Blind)<br>
            Spring4Shell<br>
            SQL Injection<br>
            SQL Injection - Hypersonic SQL<br>
            SQL Injection - MsSQL<br>
            SQL Injection - MySQL<br>
            SQL Injection - Oracle<br>
            SQL Injection - PostgreSQL<br>
            SQL Injection - SQLite<br>
            XML External Entity Attack<br>
            XPath Injection<br>
            XSLT Injection`
        },
        {
          type: 'Miscellaneous',
          content: 
            `External Redirect<br>
            Generic Padding Oracle<br>
            GET for POST<br>
            Log4Shell<br>
            Script Active Scan Rules<br>
            SOAP Action Spoofing<br>
            SOAP XML Injection`
        },
        {
          type: 'Server Security',
          content: 
            `Path Traversal<br>
            Remote File Inclusion`
        }
      ],
      headers: [
        { text: 'Package Name', value: 'name' },
        { text: 'Version', value: 'version' },
        { text: 'License', value: 'license' },
      ],
      items: [
        { name: 'Vue.js', version: '2.6.14', license: 'MIT' },
        { name: 'Vuetify', version: '2.7.2', license: 'MIT' },
        { name: 'Axios', version: '1.7.2', license: 'MIT' },
        { name: 'Vue Router', version: '3.6.5', license: 'MIT' },
        { name: 'Vuex', version: '3.6.2', license: 'MIT' },
        { name: 'Chart.js', version: '4.4.3', license: 'MIT' },
        { name: 'jspdf', version: '2.5.1', license: 'MIT' },
        { name: 'SweetAlert2', version: '11.12.4', license: 'MIT' },
        { name: 'vue-chartjs', version: '5.3.1', license: 'MIT' },
        { name: 'vue-html2pdf', version: '1.8.0', license: 'MIT' },
        { name: '@babel/core', version: '7.12.16', license: 'MIT' },
        { name: 'ESLint', version: '7.32.0', license: 'MIT' },
      ],
      owasp:[],
      selected:'overview',
      steps:[
        {
          menu: '3.2 Scanner Menu',
          steps:[
            {
              step:`In the 'Scanner' menu, enter the url to scan and the login information for that url.<br>
                    And click 'scan' button to start scan<br>
                    (* Login url, id, pw is only required if it is a page that requires login.)`,
              src:[require('@/assets/enterInfo.png')]
            }, 
            {
              step:'When the scan is complete, the scan results are output below.',
              src:[require('@/assets/scanResult.png')]
            },
            {
              step:'You can view previously scanned results in the history box on the right.',
              src:[require('@/assets/scannerHistory.png')]
            }
          ],
        },{
          menu: '3.3 Scan History Menu',
          steps:[
            {
              step:'In the scan history menu, you can view the history of your scan and the results of your previous scan.',
              src:[require('@/assets/scanHistory.png')]
            },
            {
              step:`Click the view button in the table to view the results of that scan history.`,
              src:[require('@/assets/scanHistoryView.png'), require('@/assets/viewPage.png')]
            },
            {
              step:'Click the download button in the table to download the results of that scan history.',
              src:[require('@/assets/scanHistoryDownload.png'), require('@/assets/downloadPage.png')]
            }
          ],
        }

      ]
    }),
    created(){
      this.owasp=owaspList.owasp
    },
    mounted(){
      var secondMenu= document.getElementById("rule");
      var thirdMenu= document.getElementById('how');
      var fourthMenu= document.getElementById('dev');

      var secondHeight=secondMenu.getBoundingClientRect().top-150;
      var thirdHeight=thirdMenu.getBoundingClientRect().top-200;
      var fourthHeight=fourthMenu.getBoundingClientRect().top-200;

      window.addEventListener("scroll", function(){
        var scrollPosition = window.scrollY;

        this.selected=scrollPosition>=fourthHeight
        ?'dev':scrollPosition>=thirdHeight
        ?'how':scrollPosition>=secondHeight
        ?'rule':'overview';
        console.log(this.selected);
      }.bind(this));
    },
    methods:{
      isText:function(text){
        if(text.startsWith("@")){
          return true
        }else{
          return false;
        }
      },
      changeText: function(text){
        return text.replace("@","- ")
      },
      changeLocation:function(location){
        this.selected=location;
        var location1=document.getElementById(location).offsetTop;
        window.scrollTo({top:location1, behavior:'smooth'});
      },
      gotop:function(){
        window.scrollTo({top:0, behavior:'smooth'});
        this.selected='overview';
      }
    },
    // watch:{
    //   selected:{
    //     handler(newVal){
    //       this.selected=newVal;
    //       console.log(newVal);
    //     },
    //     immediate:true
    //   }
    // }
}
</script>

<style scoped>
.highlight{
  background-color: #B0BEC5 !important;
  font-weight: 500;
  color: white !important;
}
.gotop{
  filter: invert(63%) sepia(40%) saturate(2909%) hue-rotate(359deg) brightness(100%) contrast(108%);
}
.screenshot{
  width: 450px; 
  height: 250px; 
  margin-top: 5px;
}
.comm_bullet{
    font-size:10.5pt;
    font-family:Wingdings;
    mso-fareast-font-family:굴림;
    
    mso-bidi-font-family:Helvetica;
    color:#333333;
    mso-font-kerning:0pt
}
</style>