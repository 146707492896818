import Vue from "vue";
import VueRouter from "vue-router";
import AboutMe from "@/views/introduction.vue";
import LoginPage from "@/views/login.vue";
import ScanPage from "@/views/scan.vue";
import ScanHistory from "@/views/history.vue";
import Signup from "@/views/signup.vue";
import Settings from "@/views/settings.vue";
import store from "@/store/store";
import axios from "axios";
// import { address } from '@/mixins/api';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'AboutMe',
        component: AboutMe
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage
    },
    {
        path: '/scan',
        name: 'ScanPage',
        component: ScanPage,
        meta: { requiresAuth: true },
    },
    {
        path: '/history',
        name: 'ScanHistory',
        component: ScanHistory,
        meta: { requiresAuth: true },
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: Signup
    },
    {
        path: '/settings',
        name: 'SettingPage',
        component: Settings,
        meta: {requiresAuth: true}
    }
];

const router = new VueRouter({
    routes,
    mode: 'history'
});

//로그아웃 되는 경우 다른 페이지 접근 처리
router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        try {
            await axios.post(
                `${this.$apiUrl}/webscan/history`,
                {},
                { withCredentials: true }
              );
              console.log("request was sent")
            if (store.state.isLogged) {
                next();
            } else {
                next({
                    path: '/login',
                    query: { redirect: to.fullPath },
                });
            }
        } catch (error) {
            console.log('Error during authentication check:', error);
            console.log('Logout ')
            if (error.response && error.response.status === 401) {
                store.commit('logout');
                next({
                    path: '/login',
                    query: { redirect: to.fullPath },
                });
            } else {
                next();
            }
        }
    } else {
        next();
    }
});

export default router;