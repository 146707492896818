<template>
  <v-card
  id="top"
  elevation="0"
  :class="['scrollbar', showCloseButton ? 'popup' : 'pa-1']"
  style="height: 100%; overflow-y: scroll; position: relative;"
  >
    <div v-if="$vuetify.breakpoint.width>=500" style="width: 100%" class="d-flex align-center">
      <v-col class="pa-1">
          <v-card
          class="pa-3 d-flex align-center"
          elevation="0"
          style="height: 100%; border: 1px solid #b0bec5"
          >
          <div v-if="display" class="fontBlack text-h6">Target: {{ scanUrl }}</div>
          <div v-if="!display" class="fontBlack text-h6">Target: <br> {{ scanUrl }}</div>
        </v-card>
      </v-col>
      <v-col class="pa-1">
        <v-card
        elevation="0"
        style="height: 100%; border: 1px solid #b0bec5"
              class="pa-3 d-flex align-center"
              >
              <div v-if="display" class="fontBlack text-h6">Time Taken: 
                {{ timeTaken }}
              </div>
              <div v-if="!display" class="fontBlack text-h6">Time Taken: 
                <br>{{ timeTaken }}
              </div>
        </v-card>
      </v-col>
    </div>
    <div v-if="$vuetify.breakpoint.width<500" style="width: 100%" class="d-flex flex-column align-center">
      <v-col class="pa-1">
          <v-card
          class="pa-3 d-flex align-center"
          elevation="0"
          style="height: 100%; border: 1px solid #b0bec5"
          >
          <!-- <div v-if="display" class="fontBlack text-h6">Target: {{ scanUrl }}</div> -->
          <div v-if="!display" class="fontBlack text-h6">Target: <br> {{ scanUrl }}</div>
        </v-card>
      </v-col>
      <v-col class="pa-1">
        <v-card
        elevation="0"
        style="height: 100%; border: 1px solid #b0bec5"
              class="pa-3 d-flex align-center"
              >
              <!-- <div v-if="display" class="fontBlack text-h6">Time Taken: 
                {{ timeTaken }}
              </div> -->
              <div v-if="!display" class="fontBlack text-h6">Time Taken: 
                <br>{{ timeTaken }}
              </div>
        </v-card>
      </v-col>
    </div>
    
    <!-- <v-row style="margin-top: 10px"> -->
      <div style="width: 100%; padding: 5px;" :class="display?'d-flex align-center':'d-flex flex-column align-center'" >
        <horizontal-chart :vulnLevel1="vulnLevel1" :style="display?'width:65%; height:100%;':'width:100%;'"></horizontal-chart>
        <v-card
        elevation="1"
        outlined
        color="blue-grey lighten-5"
        :style="display?'padding: 20px; width: 35%;':'width: 90%; padding: 20px;'"
        >
          <div class="text-h5 font-weight-bold" style="margin-bottom: 5px">
            TOTAL {{ findings }}
          </div>
          <div
          v-for="(vuln, idx) in vulnLevel1"
          :key="idx"
          style="
                display: flex;
                justify-content: start;
                align-items: center;
                width: 100%;
                position: relative;
                margin-bottom: 5px;
              "
            >
              <v-sheet :color="vuln.color" width="15" height="15"></v-sheet>
              <div
              class="text-subtitle-1 font-weight-medium"
              style="margin-left: 10px"
              >
              {{ vuln.level }}
            </div>
            <div
            class="text-h5 font-weight-bold"
            style="position: absolute; right: 0px"
            >
            {{ vuln.value }}
            </div>
          </div>
        </v-card>
      </div>
      <!-- <v-col cols="8">
      </v-col>
      <v-col cols="4" style="padding: 0px 50px; padding-left: 0px; display: flex; justify-content: center; align-items: center;">
      </v-col> -->
    <!-- </v-row> -->
      
    <v-card-text 
      style="position: relative;"
      :style="$vuetify.breakpoint.width<500?'padding:5px !important;':''"
    >
      <v-btn
        icon
        style="position: absolute; z-index: 300; right: 10px; bottom: 0px; width: 50px; height: 50px;"
        @click="goTop"
      >
        <img src="@/assets/arrow-up-drop-circle.svg" class="blueGrey">
      </v-btn>
      <div class="fontBlack text-h6 font-weight-regular">
        Vulnerabilities Found
      </div>
      <div style="width: 100%">
        <v-card v-if="resultData.length == 0">
          <v-card-text>There's no Vulnerabilities</v-card-text>
        </v-card>
        <div v-if="resultData.length">
          <v-expansion-panels
          v-for="(data, idx) in resultData"
          :key="idx"
          multiple
          focusable
          >
          <v-expansion-panel v-for="(item, i) in data" :key="i" >
            <v-expansion-panel-header style="padding: 0px !important" expand-icon="" >
                <v-simple-table dense>
                  <tbody>
                    <tr>
                      <td
                      class="text-left"
                      style="min-width: 130px; width: 130px"
                      >
                      <div
                      style="
                            display: flex;
                            justify-content: left;
                            align-items: center;
                          "
                        >
                          <div
                            :class="item.risk"
                            style="
                              width: 12px;
                              height: 12px;
                              border-radius: 15px;
                              margin-right: 5px;
                            "
                          ></div>
                          {{ changeName(item.risk) }}
                        </div>
                      </td>
                      <td
                        class="text-left"
                        style="min-width: max-content; width: 300px"
                        v-if="item.risk !== 'Malware' && item.risk !== 'Well-Known'"
                        v-html="item.alert"
                        ></td>
                        <td
                        v-if="item.risk === 'Malware'"
                        class="text-left"
                        style="min-width: max-content; width: 300px"
                        v-html="
                          `Malware was found in ${malwareCnt} of ${item.cnt} resources`
                        "
                      ></td>
                      <td
                      v-if="item.risk === 'Well-Known'"
                      class="text-left"
                        style="min-width: max-content; width: 300px"
                        v-html="
                          `Well-Known was found in ${cryptoCnt} of ${item.cnt} resources`
                        "
                      ></td>
                      <td class="text-center tdWidth">Instances</td>
                      <td
                      v-if="item.risk !== 'Malware' && item.risk !== 'Well-Known'"
                      class="text-left"
                      style="min-width: max-content; width: 50px"
                      >
                      {{ item.cnt }}
                    </td>
                    <td
                    v-if="item.risk === 'Malware'"
                    class="text-left"
                        style="min-width: max-content; width: 50px"
                      >
                        {{ malwareCnt }}
                      </td>
                      <td
                      v-if="item.risk === 'Well-Known'"
                      class="text-left"
                      style="min-width: max-content; width: 50px"
                      >
                      {{ cryptoCnt }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-expansion-panel-header>

              <v-expansion-panel-content>
                <template v-if="item.risk.toLowerCase() === 'malware'">
                  <v-simple-table
                  dense
                  v-for="(inst, k) in item.instance.slice().sort((a, b) => {
                    const descA =
                    a.description !== 'no search malware at Url\n' &&
                    a.description !== 'Connection failed please check url\n' &&
                    a.description !== '' &&
                    a.description !== null;
                    const descB =
                    b.description !== 'no search malware at Url\n' &&
                    b.description !== 'Connection failed please check url\n' &&
                    b.description !== '' &&
                    b.description !== null;
                    return descA === descB ? 0 : descA ? -1 : 1;
                    })"
                    :key="k"
                    style="
                      border-top: thin solid rgba(0, 0, 0, 0.12);
                      border-radius: 0px;
                    "
                  >
                  <tbody
                  v-if="
                        inst.description !== 'no search malware at Url\n' &&
                        inst.description !== 'Connection failed please check url\n' &&
                        inst.description !== '' &&
                        inst.description !== null
                      "
                    >
                    <tr>
                      <td
                          rowspan="3"
                          class="text-center"
                          style="border: transparent; width: 100px"
                        >
                          Instance
                        </td>
                        <td class="text-left" style="width: 200px">URL</td>
                        <td class="text-left">{{ inst.url }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">DESCRIPTION</td>
                        <td class="text-left">{{ inst.description }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">SOLUTION</td>
                        <td class="text-left">{{ inst.solution }}</td>
                      </tr>
                    </tbody>
                    <tbody
                    v-if="
                      inst.description === 'no search malware at Url\n' ||
                      inst.description === 'Connection failed please check url\n' ||
                      inst.description === '' ||
                      inst.description === null
                    "
                    >
                      <tr>
                        <td
                          rowspan="3"
                          class="text-center"
                          style="border: transparent; width: 100px"
                        ></td> <td class="text-left" style="width: 200px">URL</td>
                        <td class="text-left">{{ inst.url }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">DESCRIPTION</td>
                        <td class="text-left">{{ inst.description }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </template>

                <template v-else-if="item.risk === 'Well-Known'">
                  <v-simple-table
                    dense
                    v-for="(inst, k) in item.instance.slice().sort((a, b) => {
                      const descA =
                        a.description !== 'It does not match the Well-Known'
                      const descB =
                        b.description !== 'It does not match the Well-Known'
                      return descA === descB ? 0 : descA ? -1 : 1;
                    })"
                    :key="k"
                    style="
                      border-top: thin solid rgba(0, 0, 0, 0.12);
                      border-radius: 0px;
                    "
                  >
                    <tbody
                      v-if="
                        inst.description !== 'It does not match the Well-Known'
                      "
                    >
                      <tr>
                        <td
                          :rowspan="calrow(inst.description)"
                          class="text-center"
                          style="border: transparent; width: 100px"
                        >
                          Instance
                        </td>
                        <td class="text-left" style="width: 200px">URL</td>
                        <td class="text-left">{{ inst.url }}</td>
                      </tr>
                      <tr v-for="(item, index) in cryptoDesc(inst.description)" :key="index">
                        <td class="text-left" v-if="index==0" :rowspan="calrow(inst.description)-2">DESCRIPTION</td>
                        <!-- <td class="text-left" v-html="cryptoDesc(inst.description)"></td> -->
                        <td class="text-left">
                          {{ item.match_list[0] }} <strong> - {{item.category}}</strong><br><br>
                          Description: {{ item.desc }}<br><br>
                          Reference: {{item.reference}} <br><br>
                          Solution: {{item.solution}}
                        </td>
                      </tr>
                    </tbody>
                    <tbody
                      v-if="
                          inst.description === 'It does not match the Well-Known'
                      "
                    >
                      <tr>
                        <td
                          rowspan="3"
                          class="text-center"
                          style="border: transparent; width: 100px"
                        ></td>
                          <td class="text-left" style="width: 200px">URL</td>
                          <td class="text-left">{{ inst.url }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">DESCRIPTION</td>
                        <td class="text-left">{{ inst.description }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </template>

                <template v-else>
                  <v-simple-table dense>
                    <tbody>
                      <tr>
                        <td class="text-left">Description</td>
                        <td style="text-align: justify">
                          {{ item.description!=""?item.description:"N/A" }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">Solution</td>
                        <td style="text-align: justify">{{ item.solution!=""?item.solution:"N/A" }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">Other information</td>
                        <td class="text-left">{{ item.other!=""?item.other:"N/A" }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">References</td>
                        <td class="text-left">{{ item.reference!=""?item.reference:"N/A" }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">CWE ID</td>
                        <td class="text-left" v-html="item.cweid"></td>
                      </tr>
                      <tr>
                        <td class="text-left">WASC ID</td>
                        <td class="text-left" v-html="item.wascid"></td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <v-simple-table
                    dense
                    v-for="(inst, k) in item.instance"
                    :key="k"
                    style="
                      border-top: thin solid rgba(0, 0, 0, 0.12);
                      border-radius: 0px;
                    "
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="3"
                          class="text-center"
                          style="border: transparent; width: 100px"
                        >
                          Instance
                        </td>
                        <td class="text-left" style="width: 200px">URL</td>
                        <td class="text-left">{{ inst.url }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">METHOD</td>
                        <td class="text-left">{{ inst.method }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">Param</td>
                        <td class="text-left">{{ inst.param }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </v-card-text>

    <v-btn
      v-if="showCloseButton"
      icon
      :outlined="$vuetify.breakpoint.width<600?true:false"
      :text="$vuetify.breakpoint.width<600?true:false"
      :elevation="$vuetify.breakpoint.width<600?'1':''"
      @click="$emit('close')"
      style="position: absolute; top: 5px; right: 10px"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
</v-card>
</template>

<script>
import horizontalChart from "./horizontalChart.vue";

export default {
  components: {
    horizontalChart,
  },
  name: "scanResult",
  props: {
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    isHistoryPage: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Array,
      default: () => [],
    },
    timeTaken: {
      type: String,
      default: "0",
    },
    timelines: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    resultData: [],
    malwareCnt: 0,
    allMalwares: 0,
    cryptoCnt: 0,
    allCrypto: 0,
    totalFindgins: 0,
  }),
  computed: {
    display(){
      var display=false;
      switch(this.$vuetify.breakpoint.name){
        case `lg`: display=true; break;
        case `xl`: display=true; break;
      }
      return display;
    },
    scanUrl() {
      const fullUrl = this.result[0]?.url || "N/A";
      try {
        const urlObj = new URL(fullUrl);
        return `${urlObj.protocol}//${urlObj.host}`;
      } catch (e) {
        console.warn("Invalid URL:", fullUrl);
        return "N/A";
      }
    },
    findings() {
    let total = 0;
    
    const levels = ["High", "Medium", "Low", "Informational", "Malware", "Well-Known"];

     //Malware risk 데이터를 받기 위해, 'description'을 기준으로 필터링하고, description 비어 있지 않고, null이 아니며 실제 데이터일 경우에만 저장한다
    levels.forEach((level) => {
      if (level === "Malware") {
        total += this.result
          .filter(item => item.risk && item.risk.toLowerCase() === "malware")
          .reduce((acc, item) => {
            const validInstances = item.instance?.filter(inst => 
              inst.description && 
              inst.description !== "no search malware at Url\n" &&
              inst.description !== "Connection failed please check url\n" &&
              inst.description !== "" && 
              inst.description !== null
            ) || [];
            this.allMalwares++;
            return acc + validInstances.length;
          }, 0);
          console.log(total);
      } else if(level === "Well-Known"){
        var cryptoArr=this.result.filter(item=> item.risk==="Well-Known");
        this.allCrypto=cryptoArr.length;
        this.cryptoCnt=cryptoArr.filter(inst => inst.description !== "It does not match the Well-Known").length;
        total+=this.cryptoCnt;
      } else {
        total += this.result.filter(item => 
          item.risk && item.risk.toLowerCase() === level.toLowerCase()
        ).length;
      }
    });
    
    return total > 0 ? total : 0;
  },
  vulnLevel1() {
    const levels = ["High", "Medium", "Low", "Informational", "Malware", "Well-Known"];
    const result = Array.isArray(this.result) ? this.result : [];

    return levels.map(level => {
      let count;
      if (level === "Malware") {
        count = result
          .filter(item => item.risk && item.risk.toLowerCase() === "malware")
          .reduce((acc, item) => {
            const validInstances = item.instance?.filter(inst => 
              inst.description &&
              inst.description !== "no search malware at Url\n" &&
              inst.description !== "Connection failed please check url\n" &&
              inst.description !== "" &&
              inst.description !== null
            ) || [];
            this.malwareCnt = acc + validInstances.length;
            return acc + validInstances.length;
          }, 0);
      } else if(level === "Well-Known"){
        count=result.filter(item=> item.risk==="Well-Known")
        .filter(inst => inst.description !== "It does not match the Well-Known").length;

      } else {
        count = result.filter(item => 
          item.risk && item.risk.toLowerCase() === level.toLowerCase()
        ).length;
      }
      if(level === "Informational"){
        level = "Information"
      }
      return {
        level,
        value: count,
        color: this.getColor(level),
      };
    });
  },
  },
  methods: {
    goTop:function(){
        document.getElementById("top").scrollTo({top:0, behavior:'smooth'});
    },
    calrow:function(desc){
      var arr=this.cryptoDesc(desc);
      return 2+arr.length;
    },
    cryptoDesc:function(desc){
      console.log(desc);
      var desc1= desc.replace(/'/g, '"');
      desc1 = desc1.replace(/(\{|\s)([A-Za-z_][A-Za-z0-9_]*)(:)/g, '$1"$2"$3');
      desc1 = desc1.replace(/(\[|\s)([A-Z_][A-Za-z0-9_]*)(\]|,)/g, '$1"$2"$3');
      desc1 = desc1.replace(/\n/g, '');

      const descArr= JSON.parse(desc1);
      // match_list index 확인
      console.log(descArr);
      // var matchArr=descArr.map(i => i.match_list[0]);
      return descArr;
    },
    getColor(level) {
      switch (level) {
        case "High":
          return "#FF5252";
        case "Medium":
          return "#FFAB40";
        case "Low":
          return "#FFF176";
        case "Information":
          return "#82B1FF";
        case "Malware":
          return "#BDBDBD";
        case "Well-Known":
          return "#8D6E63"
        default:
          return "#FFFFFF";
      }
    },
    //Informatinal risk 이름 Information으로 바뀜
    changeName(name) {
      const newName = name === "Informational" ? "Information" :name;
      return newName.charAt(0).toUpperCase() + newName.slice(1);
    },
  },
  watch: {
    result: {
      handler(newVal) {
        if (!Array.isArray(newVal)) {
          console.error("newVal is not an array:", newVal);
          return;
        }
        // console.log("result newVal");
        // console.log(newVal);
        this.resultData = [];
        this.malwareCnt = 0;
        this.cryptoCnt = 0;
        this.allCrypto = 0;
        const levels = ["High", "Medium", "Low", "Informational", "Malware", "Well-Known"];
        // risk 별로 분류
        const dataArr = levels.map((level) =>
          Array.isArray(newVal)
            ? newVal.filter(
                (item) =>
                  item.risk && item.risk.toLowerCase() === level.toLowerCase()
              )
            : []
        );

        console.log("dataArr")
        console.log(dataArr)

        dataArr.forEach((group) => {
          if (group.length) {// 해당 risk 발견된 경우
            const uniqueAlerts = [];
            const aggregatedData = [];
            console.log(group);

            group.forEach((item) => {
              const idx = uniqueAlerts.indexOf(item.alert);
              if (idx === -1) {// 새로운 alert 값인 경우
                uniqueAlerts.push(item.alert);
                item.cnt = group.filter((i) => i.alert === item.alert).length;
                item.instance = item.instance || [
                  {
                    url: item.url,
                    method: item.method || "N/A", 
                    param: item.param || "N/A",
                    description: item.description || "N/A",
                    solution: item.solution || "N/A"
                  },
                ];
                aggregatedData.push(item);
              } else {
                aggregatedData[idx].instance.push({
                  url: item.url || "N/A",
                  method: item.method || "N/A",
                  param: item.param || "N/A",
                  description: item.description || "N/A",
                  solution: item.solution || "N/A"
                });
                
              }
              // console.log("aggregatedData");
              // console.log(aggregatedData);
              console.log("Risk level:", item.risk);
              if (item.risk.toLowerCase() === "malware") {
                console.log("Found malware item:", item);
                if (Array.isArray(item.instance)) {
                  const validInstances = item.instance.filter((inst) => {
                    return (
                      inst.description &&
                      inst.description !== 'Connection failed please check url\n' &&
                      inst.description !== null
                    );
                  });
                  console.log("Valid inst ")
                  validInstances.map((inst, i) =>(
                    console.log(inst + i)
                  ))
                  this.malwareCnt = validInstances.length;
                  console.log("MALWARE CNT " + this.malwareCnt)
                }
              }
              if(item.risk === "Well-Known"){
                console.log("Well-Known");
                console.log(item);
                if (Array.isArray(item.instance)) {
                  this.allCrypto=item.instance.length;
                  this.cryptoCnt=item.instance.filter(item => item.description !== "It does not match the Well-Known").length;
                  
                }
              }
            });
            this.resultData.push(aggregatedData);
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
.v-tooltip__content{
  margin-top: 10px !important;
}


.v-dialog__content,
.v-dialog::-webkit-scrollbar {
  scrollbar-width: none;
  background: transparent;
}

.scrollbar {
  scrollbar-width: none;
  background: transparent;
  width: 100vw !important;
  padding-left: 0px !important;
}

.tdBorder {
  border-radius: 10px;
}

.High {
  background-color: #ff5252;
}
.Medium {
  background-color: #ffab40;
}
.Low {
  background-color: #fff176;
}
.Informational {
  background-color: #82b1ff;
}
.Malware {
  background-color: #bdbdbd;
}
.Well-Known {
  background-color: #8D6E63;
}
.popup {
  padding: 15px !important;
  padding-top: 45px !important;
}
.blueGrey{
  filter: invert(88%) sepia(3%) saturate(981%) hue-rotate(155deg) brightness(89%) contrast(81%);
}
</style>
